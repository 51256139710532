@tailwind base;
@tailwind components;
@tailwind utilities;
@keyframes  fadeInOut {
    0%, 100% {
        opacity: 0;
    }
    10%, 90% {
        opacity: 1;
    }
}
    .cycle-text {
        animation: fadeInOut 4s ease-in-out infinite;
    }


html {

}
body {
    overflow-x: hidden;
}